import { ReactNode } from 'react'

import { cn } from '~/lib/utils'

type Props = {
  className?: string
  children?: ReactNode
  centered?: boolean
  useLegacyStyling?: boolean
}

export function Container(props: Props) {
  return (
    <div
      className={cn(
        props.useLegacyStyling
          ? 'max-w-[1280px] px-8 mx-auto min-w-[1280px]'
          : 'max-w-[1280px] px-6 sm:px-8',
        props.centered ? 'mx-auto' : '',
      )}
    >
      <div className={props.className}>{props.children}</div>
    </div>
  )
}
